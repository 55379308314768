.article p {
  color: rgb(20, 20, 20);
  padding-bottom: 10px;
  line-height: 1.4em;
  font-family: sans-serif;
  margin: 0px;
  font-size: 16px;
}

.article a {
  color: rgb(20, 20, 20);
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 1.4em;
  font-family: sans-serif;
  font-size: 16px;
}

.article li {
  color: rgb(20, 20, 20);
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 1.4em;
  font-family: sans-serif;
  font-size: 16px;
}

.article strong {
  color: #0b6471;
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 1.4em;
  font-family: sans-serif;
  font-size: 16px;
}

.article table {
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 0px;
  table-layout: fixed;
}

.article table tr td p {
  font-size: 10px;
  word-wrap: break-word;
  border-collapse: collapse;
}

.article table tr:first-child td {
  background-color: #4e97a5;
  font-size: 14px;
  color: white;
}

.article table tr:first-child td p {
  background-color: #4e97a5;
  font-size: 14px;
  color: white;
}

.article td {
  padding: 5px;
}

.article p img {
  max-width: 500px;
}

@media only screen and (max-width: 600px) {
  .article table tr td {
    font-size: 6px;
  }

  .article table tr:first-child td {
    background-color: #4e97a5;
    font-size: 6px;
    color: white;
  }

  .article table tr:first-child td p {
    background-color: #4e97a5;
    font-size: 6px;
    color: white;
  }
  .article p img {
    max-width: 300px;
  }
}

.MuiButton-label {
  text-transform: none;
}

.stage {
  border-radius: 5px;
  border-bottom: 5px solid darkgray;
  padding: 0px;
  margin-bottom: 18px;
  margin-top: 22px;
  font-size: 22px;
  font-weight: 500;
  color: #0b6471;
  font-family: sans-serif;
  line-height: 1.5em;
}
