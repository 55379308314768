body {
  font-family: Roboto, sans-serif;
  color: #333;
  font-size: 16px;
  line-height: 22px;
  scroll-behavior: smooth;
}

h1 {
  margin-top: 0px;
  margin-bottom: 19px;
  font-size: 38px;
  line-height: 44px;
  font-weight: 500;
}

h2 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  text-align: center;
}

h3 {
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}

p {
  margin-top: 0px;
  margin-bottom: 5px;
}

img {
  display: inline-block;
  max-width: 100%;
}

.button {
  display: inline-block;
  margin-right: 14px;
  padding: 13px 23px;
  border-radius: 3px;
  background-color: #000;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: background-color 300ms ease, box-shadow 300ms ease, color 300ms ease;
  transition: background-color 300ms ease, box-shadow 300ms ease, color 300ms ease;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  text-align: center;
  text-decoration: none;
 

}

.button:hover {
  background-color: #0B6471;
}

.button.hollow {
  background-color: transparent;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.12), inset 0 0 0 2px #000;
  color: #000;
  text-shadow: none;
}

.button.hollow:hover {
  background-color: #0B6471;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.12), inset 0 0 0 2px #0B6471;
  color: #FE8E3C;
  text-shadow: none;
}

.button.in-pricing {
  display: block;
  margin-top: 19px;
  margin-right: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.logo-text {
  margin-top: 24px;
  font-family: 'Roboto', sans-serif;
  color: #1c1c1c;
  font-size: 27px;
  font-weight: 500;
}

.logo-text.footer {
  margin-top: 0px;
  margin-bottom: 19px;
  color: #e6eaed;
}

.navigation {
  left: 0px;
  top: 0px;
  right: 0px;
  background-color: #fff;

  scroll-behavior: smooth;
}

.nav-link {
  padding: 0px 17px;
  box-shadow: inset 0 3px 0 0 transparent;
  background-color: transparent;
  -webkit-transition: background-color 500ms ease, color 300ms ease;
  transition: background-color 500ms ease, color 300ms ease;
}

.nav-link:hover {
  color: orange;
}

.MuiLink-root {
  color: black !important;
  -webkit-transition: background-color 500ms ease, color 300ms ease;
  transition: background-color 500ms ease, color 300ms ease;
}

.MuiLink-root:hover {
  color: orange !important;

}


.nav-link.w--current {
  box-shadow: inset 0 3px 0 0 #4E97A5;
  color: #4E97A5;
}

.section {
  min-height: 0vw;
  padding-top: 30px;
  padding-bottom: 30px;
  scroll-behavior: smooth;
}

.section.blue {
  background-color:#BFFBFF;
  scroll-behavior: smooth;
}

.section.main {
  margin-top: 5px;
  background-color: #4E97A5;  
  border-radius: 5px;
  background-image: url('../images/analytics-graphic2.png');
  background-position: 0% 23%;
  background-size: cover;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.23);
  scroll-behavior: smooth;
}

.section.grey {
  background-color: #f0f0f0;
  scroll-behavior: smooth;
}

.section.image {
  background-image: url('../images/town.jpg');
  background-position: 50% 50%;
  background-size: cover;
  color: #fff;
  scroll-behavior: smooth;
}

.section.footer {
  padding-top: 42px;
  padding-bottom: 42px;
  background-color: #2b2c2e;
  color: #e4e8eb;
  scroll-behavior: smooth;
}

.section.footer.copyright {
  padding-top: 19px;
  padding-bottom: 19px;
  background-color: #1c1d1f;
  color: #939596;
  font-size: 14px;
  scroll-behavior: smooth;
}

.section.purple {
  min-height: 0vw;
  background-color: #dde7ed;
  scroll-behavior: smooth;
}

.section.clients {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #a1bfd4;
  scroll-behavior: smooth;
}

.section.press {
  padding: 13px 59px 0px;
  border-radius: 5px;
  background-color: #0B6471;
  scroll-behavior: smooth;
}

.slider {
  display: none;
  height: 360px;
  background-color: #303030;
  scroll-behavior: smooth;
}

.slide {
  padding-top: 64px;
  padding-bottom: 64px;
  scroll-behavior: smooth;
}

.slide._1 {
  background-color: #00ad6e;
  scroll-behavior: smooth;
}

.slide._2 {
  background-color: #00bdd6;
  scroll-behavior: smooth;
}

.slide._3 {
  background-color: #ccbe00;
  scroll-behavior: smooth;
}

.main-subtitle {
  margin-bottom: 26px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
}

.footer-slogan {
  margin-bottom: 11px;
  color: #939596;
}

.social-icon {
  margin-right: 11px;
  opacity: 0.41;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}

.social-icon:hover {
  opacity: 1;
}

.footer-title {
  margin-top: 0px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

.page-link {
  border-bottom: 1px solid transparent;
  -webkit-transition: color 300ms ease, border 300ms ease;
  transition: color 300ms ease, border 300ms ease;
  color: #0B6471;
  text-decoration: none;
}

.page-link:hover {
  border-bottom-color: #0B6471;
}

.page-link.in-footer {
  display: block;
  color: #939596;
}

.page-link.in-footer:hover {
  border-bottom-color: transparent;
  color: #d2d7d9;
}

.page-link.white {
  border-bottom-color: hsla(0, 0%, 100%, 0.49);
  color: #fff;
}

.page-link.white:hover {
  border-bottom-color: #fff;
}

.newsletter-field {
  width: 60%;
  height: 40px;
  float: left;
  border: 1px solid transparent;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  background-color: #e8e8e8;
}

.newsletter-field:focus {
  border-width: 1px;
  border-color: #09f;
  box-shadow: 0 0 6px 0 #0B6471;
}

.newsletter-button {
  width: 40%;
  height: 40px;
  padding-top: 9px;
  padding-bottom: 9px;
  float: left;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: #5f6166;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #d5d8db;
}

.newsletter-button:hover {
  background-color: #0B6471;
  color: #fff;
}

.newsletter-form {
  width: 70%;
}

.brand-link {
  text-decoration: none;
}

.success-message {
  background-color: #5f6166;
}

.main-heading {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  font-size: 38px;
  line-height: 44px;
}

.section-subtitle {
  overflow: visible;
  margin-bottom: 23px;
  padding-left: 0px;
  font-size: 19px;
  line-height: 22px;
  font-weight: 300;
  text-align: center;
}

.bold-text {
  font-weight: 400;
}

.plan-wrapper {
  min-height: 300px;
  padding: 17px;
  border-radius: 3px;
  background-color: #fff;
  color: #2b2b2b;
}

.price-point {
  margin-bottom: 8px;
  font-size: 14px;
}

.price-accent {
  color: #0B6471;
  font-size: 20px;
  letter-spacing: 0px;
}

.addl-feature {
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 18px;
}

.addl-feature.inactive {
  color: #c7c5c5;
  text-decoration: line-through;
}

.divider {
  width: 50%;
  height: 1px;
  margin-top: 13px;
  margin-bottom: 12px;
  background-color: #dbdbdb;
}

.additional-contact {
  margin-top: 21px;
  text-align: center;
}

.feature {
  text-align: center;
}

.feature.centered {
  text-align: center;
}

.feature-row {
  margin-bottom: 14px;
}

.testimonial-slider {
  height: auto;
  margin-top: 45px;
  background-color: transparent;
}

.testimony-slide {
  padding-top: 0px;
  padding-right: 10%;
  padding-left: 10%;
  text-align: center;
}

.testimony-text {
  margin-bottom: 21px;
  font-family: 'Roboto', sans-serif;
  font-size: 25px;
  line-height: 31px;
  font-weight: 300;
  text-align: center;
}

.slide-nav {
  display: none;
  font-size: 10px;
}

.person {
  width: 70px;
  margin-bottom: 13px;
  border-radius: 100%;
}

.name {
  font-weight: 500;
}

.slider-arrow {
  width: 50px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: #bdbdbd;
  font-size: 32px;
}

.slider-arrow:hover {
  color: #000;
}

.feature-icon {
  margin-bottom: 12px;
}

.main-feature-group {
  margin-top: 30px;
}

.center-contents {
  text-align: center;
}

.small-features-row {
  margin-top: 71px;
}

.client-logo {
  width: 16.6%;
}

.press-logo {
  width: 16%;
  margin-right: 2%;
  margin-left: 2%;
  opacity: 0.94;
}

.small-text {
  margin-bottom: 14px;
  color: hsla(0, 0%, 100%, 0.31);
  font-size: 16px;
  font-weight: 300;
  text-align: center;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.logo {
  width: 14%;
  margin-right: 2%;
  margin-left: 2%;
}

.columns {
  margin-top: 26px;
  margin-bottom: 26px;
  padding-bottom: 0px;
}

.container {
  padding-left: 10px;
}

.list-item {
  font-style: normal;
  text-align: left;
  margin-bottom: 5px;
}

.text-span {
  color: #0B6471;
  text-decoration: underline;
}

.paragraph {
  margin-top: 7px;
  text-align: center;
}

.div-block-2 {
  padding-bottom: 41px;
}

.heading {
  color: #fff;
}

.paragraph-2 {
  color: #fff;
}

.heading-2 {
  color: #fff;
  text-align: center;
}

.paragraph-3 {
  color: #fff;
}

.heading-3 {
  color: #fff;
}

.paragraph-4 {
  color: #fff;
}

.heading-4 {
  color: #fff;
}

.paragraph-5 {
  color: #fff;
}

.image-3 {
  color: #fff;
}

.image-4 {
  color: #fff;
}

.image-5 {
  text-align: center;
}

.image-6 {
  text-align: center;
}

.div-block-3 {
  text-align: center;
}

.list {
  font-style: normal;;
  text-decoration: none;
}

.paragraph-6 {
  color: #fc0707;
}

.image-7 {
  padding-top: 17px;
  padding-bottom: 7px;
}

.image-8 {
  text-align: center;
}

.image-9 {
  text-align: center;
}

.div-block-4 {
  text-align: center;
}

.heading-5 {
  margin-top: 10px;
  padding-top: 0px;
}

.columns-2 {
  text-align: center;
}

html.w-mod-js *[data-ix="fade-in-on-load"] {
  opacity: 0;
  -webkit-transform: translate(0px, 24px);
  -ms-transform: translate(0px, 24px);
  transform: translate(0px, 24px);
}

html.w-mod-js *[data-ix="fade-in-on-scroll"] {
  opacity: 0;
  -webkit-transform: translate(0px, 24px);
  -ms-transform: translate(0px, 24px);
  transform: translate(0px, 24px);
}

@media screen and (max-width: 991px) {
  .button.in-pricing {
    font-size: 14px;
    line-height: 18px;
  }
  .nav-link {
    padding: 18px 0px;
    color: #fff;
  }
  .nav-link:hover {
    box-shadow: inset 0 3px 0 0 transparent;
  }
  .nav-link.w--current {
    box-shadow: inset 0 1px 0 0 transparent;
  }
  .nav-link.menu {
    padding: 22px 17px;
    color: #000;
    font-size: 30px;
  }
  .nav-link.menu.w--open {
    background-color: #000;
    color: #fff;
  }
  .nav-menu {
    padding-top: 13px;
    padding-bottom: 13px;
    background-color: #000;
  }
  .main-feature-group {
    margin-top: 0px;
  }
  .main-image {
    margin-top: 49px;
  }
}

@media screen and (max-width: 767px) {
  h3 {
    margin-bottom: 8px;
  }
  .logo-text {
    margin-top: 15px;
  }
  .nav-link.menu {
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .section {
    padding-top: 43px;
    padding-bottom: 43px;
    text-align: center;
  }
  .section.main {
    margin-top: 51px;
  }
  .nav-menu {
    padding-right: 12px;
    padding-left: 12px;
  }
  .footer-title {
    margin-top: 35px;
  }
  .newsletter-form {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .section-subtitle {
    margin-bottom: 44px;
  }
  .plan-wrapper {
    margin-right: 5%;
    margin-bottom: 34px;
    margin-left: 5%;
  }
  .divider {
    width: 100%;
  }
  .feature {
    margin-bottom: 32px;
    text-align: center;
  }
  .feature-icon {
    margin-bottom: 4px;
  }
  .main-feature-group {
    margin-top: 0px;
    margin-bottom: 34px;
  }
}

@media screen and (max-width: 479px) {
  .client-logo {
    width: 33.33%;
  }
  .press-logo {
    width: 28%;
  }
}

