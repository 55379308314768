#article .readAvatar {
    visibility: hidden;
    cursor: pointer;
}

#article:hover .readAvatar {
    visibility: visible;
    cursor: pointer;
}

#article:hover {
    /*box-shadow: 9px 10px 5px -3px rgba(184,184,184,0.78);*/
    cursor: pointer;
    transform: scale(1.05)
}

#article {
    transition: all .2s ease-in-out;
}

.coPlusWikiNewsHeader {
    color: #0B6471;
    font-size: 17px;
    margin-bottom: 6px;
    font-weight: bold;
}

.coPlusWikiNewsSlot {
    margin-bottom: 12px;
}

coPlusWikiNewsShort {
    margin-bottom: 6px;
}

.coPlusWikiNewsAllContent {
    margin-left: 16px;
}