p {
    margin: 0px;
    margin-bottom: 5px;
  }
  b {
    font-weight: 400;
  }
  borange {
    font-weight: 400;
    color: #fe8e3c;
  }
  
  .container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  .filterDiv {
    font-family: "Roboto", sans-serif;
    float: left;
    color: black;
    text-align: left;
    margin: 2px;
    display: none; /* Hidden by default */
    margin-top: 5px;
    border-radius: 5px;
    flex-direction: row;
    align-items: center;
  }
  
  /* The "show" class is added to the filtered elements */
  .show {
    display: flex;
    animation: fadeInFromNone 0.5s ease-out;
  }
  
  /* Style the buttons */
  .btn {
    border: none;
    outline: none;
    padding: 6px 16px;
    background-color: #f1f1f1;
    cursor: pointer;
    border-radius: 3px;
    margin-right: 3px;
    margin-bottom: 3px;
    width: 150px;
  }
  
  /* Add a light grey background on mouse-over */
  .btn:hover {
    background-color: #ddd;
  }
  
  /* Add a dark background to the active button */
  .btn.active {
    background-color: #0b6471;
    color: white;
  }
  
  @keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }
  
    1% {
      display: flex;
      opacity: 0;
    }
  
    100% {
      display: flex;
      opacity: 1;
    }
  }
  
  .tabledataoptions {
    display: flex;
    opacity: 0.1;
    transition: opacity 0.3s;
  }
  
  .tabledatarow:hover .tabledataoptions {
    display: flex;
    opacity: 1;
  }
  
  .MUILink {
    cursor: pointer;
  }
  
  .MuiFormControl-root {
    margin-top: 0px;
  }
  
  .MuiFormControl {
    margin-top: 0px;
  }
  