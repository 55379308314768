/* Slideshow */

.reference-mySlides {
  display: none;
}

/* Slideshow container */
.reference-slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
  min-height: 130px;
  overflow: hidden;
  margin-top: 50px;
}

/* The dots/bullets/indicators */
.reference-dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  cursor: pointer;
}

.reference-active {
  background-color: #717171;
}

/* Fading animation */
.reference-fade {
  animation-name: reference-fade;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
}

.referenceText {
  font-size: 16px;
  font-style: italic;

}

.referenceAuthor {
  font-size: 16px;
  margin-top: 10px;
  font-weight: bold;
}

@keyframes reference-fade {
  from {
    transform: translateX(40%);
    opacity: .0
  }

  to {
    transform: translateX(0);
    opacity: 1
  }
}