.article p {
    color: rgb(20,20,20);
    padding-bottom: 10px;
    line-height: 1.4em;
    font-family: sans-serif;
    margin: 0px;
    font-size: 16px;
}

.article a {
    color: rgb(20,20,20);
    padding-bottom: 0px;
    margin-bottom: 0px;
    line-height: 1.4em;
    font-family: sans-serif;
    font-size: 16px;
}

.article li {
    color: rgb(20,20,20);
    padding-bottom: 0px;
    margin-bottom: 0px;
    line-height: 1.4em;
    font-family: sans-serif;
    font-size: 16px;
}

.article strong {
    color: #0B6471;
    padding-bottom: 0px;
    margin-bottom: 0px;
    line-height: 1.4em;
    font-family: sans-serif;
    font-size: 16px;
}

.article table {
    border-radius: 5px;
    border: 1px solid lightgrey;
    padding: 0px;
}

.article table tr:first-child  td {
    background-color: rgb(230,230,230);
    font-size: 14px;
}

.article td{padding: 5px}

.article p img {
    max-width: 100%;
}

@media only screen and (max-width: 600px) {
    .article table tr:first-child  td {
        background-color: rgb(230,230,230);
        font-size: 9px;
    }
  }

.MuiButton-label {
    text-transform: none;
 }

 .Demo__some-network__share-button {
    transition: all 0.5s;
  }

 .Demo__some-network__share-button:hover:not(:active) {
    opacity: 0.75;
  }

