.filterTableRow {
  height: 22px;
}

.filterTableRow:hover {
  background-color: #efefef;
}

.virtualRow {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.virtualRow:hover {
  background-color: #efefef;
}

#wrapper {
  width: 100%;
  max-width: 325px;
  margin: 0px auto;
}

.gears {
  width: 100%;
  display: inline-block;
}

.gears:first-child {
  margin-right: 3%;
}

.gears-container {
  width: 150px;
  height: 150px;
  font-size: 24px;
  padding: 9%;
  position: relative;
  margin: 0px auto;
}

.gear-rotate {
  width: 2em;
  height: 2em;
  top: 50%;
  left: 50%;
  margin-top: -1em;
  margin-left: -1em;
  background: #0B6471;
  position: absolute;
  border-radius: 1em;
  -webkit-animation: 1s gear-rotate linear infinite;
  -moz-animation: 1s gear-rotate linear infinite;
  animation: 1s gear-rotate linear infinite;
}

.gear-rotate-left {
  margin-top: -2.2em;
  top: 50%;
  width: 2em;
  height: 2em;
  background: #0B6471;
  position: absolute;
  border-radius: 1em;
  -webkit-animation: 1s gear-rotate-left linear infinite;
  -moz-animation: 1s gear-rotate-left linear infinite;
  animation: 1s gear-rotate-left linear infinite;
}

.gear-rotate::before,
.gear-rotate-left::before {
  width: 2.8em;
  height: 2.8em;
  background:
    -webkit-linear-gradient(0deg, transparent 39%, #0B6471 39%, #0B6471 61%, transparent 61%),
    -webkit-linear-gradient(60deg, transparent 42%, #0B6471 42%, #0B6471 58%, transparent 58%),
    -webkit-linear-gradient(120deg, transparent 42%, #0B6471 42%, #0B6471 58%, transparent 58%);
  background:
    -moz-linear-gradient(0deg, transparent 39%, #0B6471 39%, #47EC19 61%, transparent 61%),
    -moz-linear-gradient(60deg, transparent 42%, #0B6471 42%, #0B6471 58%, transparent 58%),
    -moz-linear-gradient(120deg, transparent 42%, #0B6471 42%, #0B6471 58%, transparent 58%);
  background:
    -o-linear-gradient(0deg, transparent 39%, #0B6471 39%, #0B6471 61%, transparent 61%),
    -o-linear-gradient(60deg, transparent 42%, #0B6471 42%, #0B6471 58%, transparent 58%),
    -o-linear-gradient(120deg, transparent 42%, #47EC19 42%, #0B6471 58%, transparent 58%);
  background: -ms-linear-gradient(0deg, transparent 39%, #0B6471 39%, #0B6471 61%, transparent 61%), -ms-linear-gradient(60deg, transparent 42%, #0B6471 42%, #0B6471 58%, transparent 58%), -ms-linear-gradient(120deg, transparent 42%, #0B6471 42%, #0B6471 58%, transparent 58%);
  background:
    linear-gradient(0deg, transparent 39%, #0B6471 39%, #0B6471 61%, transparent 61%),
    linear-gradient(60deg, transparent 42%, #0B6471 42%, #0B6471 58%, transparent 58%),
    linear-gradient(120deg, transparent 42%, #0B6471 42%, #0B6471 58%, transparent 58%);
  position: absolute;
  content: "";
  top: -.4em;
  left: -.4em;
  border-radius: 1.4em;
}

.gear-rotate::after,
.gear-rotate-left::after {
  width: 1em;
  height: 1em;
  background: orange;
  position: absolute;
  content: "";
  top: .5em;
  left: .5em;
  border-radius: .5em;
}

/*
 * Keyframe Animations 
 */

@-webkit-keyframes gear-rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-180deg);
  }
}

@-moz-keyframes gear-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-180deg);
  }
}

@keyframes gear-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-180deg);
  }
}

@-webkit-keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }

  100% {
    -webkit-transform: rotate(210deg);
  }
}

@-moz-keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }

  100% {
    -webkit-transform: rotate(210deg);
  }
}

@keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }

  100% {
    -webkit-transform: rotate(210deg);
  }
}

.custom-tooltip-procedures {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid gray;
  display: flex;
}

.label-procedures {
  padding: 0px;
  margin: 0px;
  margin-right: 10px;
  color: green;
}

.value-procedures {
  padding: 0px;
  margin: 0px;
}

.opksth {
  color: #0b6471;
  border-bottom: 1px solid #0b6471;
  min-width: 90px;
  text-align: right;
  font-weight: 500;
}

@media only screen and (max-width: 1500px) {
  .opksth {
    min-width: 60px;
    font-size: 10px;
  }

  .opkstd {
    font-size: 10px;
  }

  .opksts {
    font-size: 10px;
  }
}

.opksth:first-child {
  min-width: 150px;
  text-align: left;
}

.opksth:last-child {
  min-width: 110px;
}

.opkstd {
  color: black;
  text-align: right;
}

.opksts {
  color: black;
  text-align: right;
  font-weight: 500;
}

.opksts.extramargin {
  padding-bottom: 7px;
}

.opksts.negative {
  color: #FE8E3C;
}

.opksts:first-child {
  text-align: left;
}

.opksik {
  margin-bottom: 20px;
}

.opksheader {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
}