// Your variable overrides
$sidebar-width: 300px !default;
$sidebar-collapsed-width: 36px !default;
$sidebar-bg-color: #F2F2F2;
$sidebar-color: #404040;
$highlight-color: #FF9933;
$submenu-bg-color: #F2F2F2;
$submenu-bg-color-collapsed: rgba(255,255,255,0.9);

@import '~react-pro-sidebar/dist/scss/styles.scss';

//.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding-left: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
};

.pro-sidebar > .pro-sidebar-inner {
    border-radius: 4px;
};